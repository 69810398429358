<template>
<div>
    <v-dialog v-model="dialog" width="600">
        <v-card>
            <bar-title-dialog title="Outras depesas - Lançamento" @close="dialog = false"></bar-title-dialog>
            <v-card-text>
                <v-row no-gutters>
                    <v-col>
                        <v-text-field v-model='editedItem.requester' outlined label="Solicitante"></v-text-field>
                        <!-- <v-select outlined  :items="['azul','verde']" label="Solicitante"></v-select> -->
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-text-field v-model='editedItem.employee' outlined label="Prestador/Colaborador"></v-text-field>
                        <!-- <v-select outlined  :items="['azul','verde']" label="Prestador/Colaborador"></v-select> -->
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-textarea v-model='editedItem.description' outlined label="Descrição do serviço">

                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <vuetify-money v-model='editedItem.value' outlined label="Valor do serviço"  :options="{ locale: 'pt-BR' ,  length: 11, precision: 0 }" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col class="text-right">
                        <v-btn x-large text class="mr-2" @click="dialog = false">fechar</v-btn>
                        <v-btn x-large :loading="loading" :disabled="loading" color="primary" @click="sendOtherExpenses()">Salvar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import { mapActions } from 'vuex'

export default {
    props: {
        value: {
            type: Boolean
        }
    },
    components: {
        BarTitleDialog,
    },
    data: () => ({
        loading:false,
        dialog: false,
        editedItem: {
            employee: '',
            requester: '',
            description: '',
            value: '0'
        }
    }),

    watch: {
        value(val) {
            this.dialog = val
        },
        dialog(val) {
            this.$emit('input', val)
        }
    },
    methods:{
      ...mapActions('cash',['otherExpenses']),
      sendOtherExpenses(){
        this.loading  = true
        this.otherExpenses(this.editedItem).then((response)=>{
          this.$toast.success('Registrado com sucesso !')
          this.dialog = false
        }).finally(()=>{
          this.loading = false
        })
      }
    }

}
</script>
