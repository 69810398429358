<template>
<div v-if="Object.keys(cash).length > 0">
    <v-card >
        <v-card-text>
            <v-row >
                <v-col>
                    <v-btn large rounded color="primary">Caixa ID: {{cash.id}}</v-btn>
                    <v-btn class="ml-2" large rounded color="warning">Fundo fixo: R$ {{ formatMoney(cash.initial_cash)}}</v-btn>
                    <v-btn class="ml-2" large rounded color="success" @click="showDialogInternConsumption()">lançar consumo interno</v-btn>
                    <v-btn class="ml-2" large rounded color="error" @click="showDialogOthersExpenses()">Outras despesas</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <i style="font-size:17px"><b>Aberto por:</b> {{ cash.user.name }} <b>em</b> {{ formatDateTime (cash.date_opened) }} - <b>Tempo de operação:</b> {{cash.open_time}}</i>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

    <!-- DIALOG OUTRAS DESPESAS -->
    <dialog-others-expenses v-model="dialogOthersExpenses"></dialog-others-expenses>

    <!-- DIALOG CONSUMO INTERNO -->
    <dialog-intern-consumption v-model="dialogInternConsumption"></dialog-intern-consumption>
</div>
</template>

<script>
import DialogOthersExpenses from './_components/DialogOthersExpenses.vue'
import DialogInternConsumption from './_components/DialogInternConsumption.vue'
import {
    mapActions
} from 'vuex'
export default {
    components: {
        DialogOthersExpenses,
        DialogInternConsumption,
    },
    props: {

    },
    data: () => ({
        dialogOthersExpenses: false,
        dialogInternConsumption: false,
        cash: {user:''},
    }),
    created() {
        this.getCash().then((response) => {
          this.cash = response.data
       }).finally(() => {

        })
    },
    methods: {
        ...mapActions('cash', ['getCash']),
        showDialogOthersExpenses() {
            this.dialogOthersExpenses = true
        },
        showDialogInternConsumption() {
            this.dialogInternConsumption = true
        },
        formatDateTime(date) {
            if (date) {

                const result = new Date(date);
                return result.toLocaleDateString('pt-BR', {
                    timeZone: 'America/Sao_Paulo'
                }) + " " + result.toLocaleTimeString('pt-BR')
            }

        },
        formatMoney(value) {
           value = parseFloat(value)
            if (value != null) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
        },

    }
}
</script>
