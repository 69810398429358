<template>
<div>
    <v-row v-if="Object.keys(this.cash).length > 0">
        <v-col>
            <v-row>
                <v-col>
                    <crm-box></crm-box>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <!-- RECEBIMENTOS -->
                    <crm-receipts></crm-receipts>

                </v-col>
                <v-col>
                    <!-- VENDAS -->
                    <crm-sales-overview></crm-sales-overview>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row v-else>
        <v-col>
            <v-alert  outlined type="error" class="text-center" style="font-size:20px" text >
                Não há caixa aberto
            </v-alert>
        </v-col>
    </v-row>

</div>
</template>

<script>
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'
import StatisticsCardAreaChart from '@core/components/statistics-card/StatisticsCardAreaChart.vue'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'

// icons
import {
    mdiLabelVariantOutline
} from '@mdi/js'

// demos
import CrmStatisticsCard from './CrmStatisticsCard.vue'
import CrmTotalSales from './CrmTotalSales.vue'
import CrmRevenueReport from './CrmRevenueReport.vue'
import CrmSalesOverview from './CrmSalesOverview.vue'
import CrmReceipts from './CrmReceipts.vue'
import CrmBox from './CrmBox.vue'
import CrmActivityTimeline from './CrmActivityTimeline.vue'
import CrmWeeklySales from './CrmWeeklySales.vue'
import CrmCardUpgradePlan from './CrmCardUpgradePlan.vue'
import CrmCardMeetingSchedule from './CrmCardMeetingSchedule.vue'
import CrmCardDeveloperMeetup from './CrmCardDeveloperMeetup.vue'
import {
    mapActions
} from 'vuex'

export default {

    data: () => ({
        cash: {},

    }),
    created() {
        this.getCash().then((response) => {
            this.cash = response.data
        }).finally(() => {

        })
    },
    methods: {
        ...mapActions('cash', ['getCash']),
    },

    components: {
        StatisticsCardWithImages,
        StatisticsCardAreaChart,
        StatisticsCardVertical,

        CrmStatisticsCard,
        CrmTotalSales,
        CrmRevenueReport,
        CrmSalesOverview,
        CrmActivityTimeline,
        CrmWeeklySales,
        CrmCardUpgradePlan,
        CrmCardMeetingSchedule,
        CrmCardDeveloperMeetup,
        CrmReceipts,
        CrmBox
    },
    setup() {
        // card with images
        const ratingsOptions = {
            statTitle: 'Ratings',
            statistics: '13k',
            change: '+38%',
            chipText: 'Year of 2021',
            chipColor: 'primary',
            avatar: require('@/assets/images/avatars/9.png'),
            avatarWidth: '111',
        }
        const sessionsOptions = {
            statTitle: 'Sessions',
            statistics: '24.5k',
            change: '-22%',
            chipText: 'Last Week',
            chipColor: 'secondary',
            avatar: require('@/assets/images/avatars/10.png'),
            avatarWidth: '86',
        }

        const growthAreaChart = {
            statTitle: 'Total Growth',
            statistics: '42.5k',
            series: [{
                name: 'Subscribers',
                data: [28, 40, 36, 52, 38, 60, 55],
            }, ],
        }

        // vertical card options
        const newProjectOptions = {
            statTitle: 'New Project',
            icon: mdiLabelVariantOutline,
            color: 'primary',
            subtitle: 'Yearly Project',
            statistics: '862',
            change: '-18%',
        }

        return {
            ratingsOptions,
            sessionsOptions,
            growthAreaChart,
            newProjectOptions,
        }
    },
}
</script>
