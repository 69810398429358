<template>
<v-card :loading="loading" :disabled="loading">
    <v-card-title class="align-start">
        <span>VENDAS</span>
        <v-spacer></v-spacer>

        <v-btn icon small class="mt-n2 me-n3">
            <v-icon size="22">
                {{ icons.mdiDotsVertical }}
            </v-icon>
        </v-btn>
    </v-card-title>

    <v-card-text>
        <v-row no-gutters>
            <v-col>
               Gráfico quantitativo de grupos de produtos
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <vue-apex-charts id="chart-sales-overview" :options="chartOptions" :series="chartData" class="h-full d-flex align-center"></vue-apex-charts>
            </v-col>

            <v-col cols="12" sm="6" class="d-flex flex-column justify-center">
                <div class="d-flex align-center">
                    <v-avatar class="v-avatar-light-bg primary--text" rounded size="40">
                        <v-icon size="30" color="primary">
                            {{ icons.mdiCurrencyUsd }}
                        </v-icon>
                    </v-avatar>
                    <div class="ms-4 d-flex flex-column">
                        <p class="text--primary mb-0 text-base">
                            Total
                        </p>
                        <span class="text--primary font-weight-semibold text-xl">R$ {{ formatMoney(totalReceive||0) }}</span>
                    </div>
                </div>

                <v-divider class="my-6"></v-divider>

                <table class="sales-overview-stats-table">
                     <tr v-for="n in rows">
                        <td v-if="labels.length > n" >
                            <div class="mb-0">
                                <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                                <span> {{labels[n]}} </span>
                            </div>
                            <span class="text-base text--primary font-weight-semibold ms-4">R$ {{formatMoney(methods[labels[n]]||0)}}</span>
                        </td>
                    </tr>
                </table>
            </v-col>
        </v-row>
    </v-card-text>
</v-card>
</template>

<script>
import {
    mdiDotsVertical,
    mdiCurrencyUsd
} from '@mdi/js'
import {
    getVuetify,
    addAlpha
} from '@core/utils'
import {
    mapActions
} from 'vuex'

export default {
    components: {
        VueApexCharts: () => import('vue-apexcharts'),
    },
    data: () => ({

        rows:[0,1,2,3,4,5,6,7,8,9,10],
        cols:[0,1],
        count:0,
        loading:false,
        totalReceive: 0,
        values: [],
        labels: [],
        methods: {},
        icons: {
            mdiDotsVertical,
            mdiCurrencyUsd,
        },
    }),
    created() {
        this.loading = true
        this.getSalesOverview().then((response) => {
            this.totalReceive = response.data.total,
            this.values = response.data.values
            this.labels = response.data.labels
            this.methods = response.data.data

        }).finally(() => {
            this.loading = false
        })
    },
    computed: {
        chartData() {
            return this.values
        },
        formatLabels() {
            return this.labels
        },
        chartOptions() {
            const $vuetify = getVuetify()
            return {
                labels: this.formatLabels,
                colors: [
                    $vuetify.theme.currentTheme.primary,
                    addAlpha($vuetify.theme.currentTheme.primary, 0.7),
                    addAlpha($vuetify.theme.currentTheme.primary, 0.5),
                    addAlpha($vuetify.theme.currentTheme.primary, 1.0),
                    addAlpha($vuetify.theme.currentTheme.primary, 0.7),
                    addAlpha($vuetify.theme.currentTheme.primary, 0.5),

                ],
                chart: {
                    type: 'donut',
                    sparkline: {
                        enabled: true,
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '70%',
                            labels: {
                                show: true,
                                name: {
                                    fontSize: '14px',
                                    offsetY: 25,
                                },
                                value: {
                                    fontSize: '2.125rem',
                                    fontWeight: 600,

                                    offsetY: -15,
                                    formatter(value) {
                                         value = parseFloat(value)
                                        //formata o valor
                                        value = value.toLocaleString('pt-br', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })


                                        return `R$ ${value}`
                                    },
                                },
                                total: {
                                    show: true,
                                    label: 'Recebimentos',
                                    color: 'rgba(94, 86, 105, 0.68)',
                                    formatter(value) {
                                        //somatorio todos os valores
                                        let total = value.globals.seriesTotals.reduce((total, num) => total + num)

                                        //formata o valor
                                        total = total.toLocaleString('pt-br', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })

                                        return `R$ ${total}`
                                    },
                                },
                            },
                        },
                    },
                },
            }
        }
    },
    methods: {
        ...mapActions('dashboard', ['getSalesOverview']),

        formatMoney(value) {
            if (value != null) {
                value = parseFloat(value)
                
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
        },

    },

}
</script>

<style lang="scss">
#chart-sales-overview {
    .apexcharts-canvas {
        .apexcharts-text {
            &.apexcharts-datalabel-value {
                font-weight: 600;
            }

            &.apexcharts-datalabel-label {
                font-size: 1rem;
            }
        }
    }
}

.sales-overview-stats-table {
    width: 100%;

    td {
        padding-bottom: 1rem;
    }

    .stats-dot {
        padding: 0.33rem;
    }

    // Set opacity of dots
    tr {
        &:nth-of-type(1) {
            td:nth-of-type(2) {
                .stats-dot {
                    opacity: 0.7;
                }
            }
        }

        &:nth-of-type(2) {
            td:nth-of-type(1) {
                .stats-dot {
                    opacity: 0.5;
                }
            }

            td:nth-of-type(2) {
                .stats-dot {
                    opacity: 0.15;
                }
            }
        }
    }
}
</style>
